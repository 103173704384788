<template>
  <v-container fluid>
    <template v-if="error">
      <v-alert type="error">
        {{ errorMessage }}
      </v-alert>

      <v-row class="pa-4">
        <v-btn text :to="{ name: 'Finance' }">
          Back to finances
        </v-btn>
      </v-row>
    </template>

    <template v-else>
      <h1>Import finance data from Notion</h1>

      <v-select
        label="Choose workspace"
        :items="workspaces"
        v-model="selectedWorkspace"
        item-text="name"
        item-value="id"
      />

      <v-select label="Choose database" :items="databases" v-model="selectedDatabase" />

      <div v-if="selectedDatabase" class="d-flex" style="overflow-x: auto;">
        <div class="pa-2" style="width: 300px;">
          <v-select
            label="Title field"
            :items="[...(properties['title'] ?? []), ...(properties['text'] ?? [])]"
            v-model="title"
          />
        </div>

        <div class="pa-2" style="width: 300px;">
          <v-select
            label="Amount field"
            :items="properties['number'] ?? []"
            v-model="amount"
          />
        </div>

        <div class="pa-2" style="width: 300px;">
          <v-select
            label="Category field"
            :items="[...(properties['select'] ?? []), ...(properties['multi_select'] ?? [])]"
            v-model="category"
          />

          <template v-if="categories.length > 0">
            <v-subheader>Choose categories to import</v-subheader>

            <v-checkbox
              v-for="cat in categories"
              :key="cat.value"
              :label="cat.text"
              :value="cat.value"
              hide-details
              v-model="selectedCategories"
            />
          </template>
        </div>

        <div class="pa-2" style="width: 300px;">
          <v-select
            label="Spent date field"
            :items="[...(properties['date'] ?? []), ...(properties['created_time'] ?? [])]"
            v-model="date"
          />
        </div>

        <div class="pa-2" style="width: 300px;">
          <v-select
            label="Type field"
            :items="[
              ...(properties['checkbox'] ?? []),
              ...(properties['text'] ?? []),
              ...(properties['number'] ?? []),
              ...(properties['select'] ?? []),
              ...['According to the sign of amount']
            ]"
            v-model="type"
          />
        </div>

        <div class="pa-2" style="width: 300px;">
          <v-select
            label="Review field"
            :items="properties['checkbox'] ?? []"
            v-model="shouldReview"
          />
        </div>
      </div>

      <v-row class="pa-4">
        <v-checkbox v-model="hourlyRun" label="Automatically run sync every hour" />
      </v-row>

      <v-row v-if="lastSync" class="pa-4 flex-column">
        <v-checkbox v-model="shouldStartOver" label="Sync all finance data from the beginning" />

        <v-subheader>Last syncronization: {{ lastSync }}</v-subheader>
      </v-row>

      <v-row class="pa-4">
        <v-btn text :to="{ name: 'Finance' }" :loading="importLoading">
          Back to finances
        </v-btn>

        <v-spacer />

        <v-btn color="primary" @click="startImport" :loading="importLoading">Import</v-btn>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      error: '',
      workspaces: [],
      selectedWorkspace: null,
      databases: [],
      selectedDatabase: null,
      properties: {},
      categories: [],
      selectedCategories: [],
      title: '',
      amount: '',
      category: '',
      date: '',
      type: '',
      shouldReview: '',
      importLoading: false,
      hourlyRun: false,
      lastSync: '',
      shouldStartOver: false,
    };
  },

  computed: {
    errorMessage() {
      if (this.error === 'access_denied') {
        return 'Attempt to connection refused.';
      }

      return 'Error in Notion connect. Please try again later.';
    },
  },

  watch: {
    selectedWorkspace() {
      if (this.selectedWorkspace) {
        this.loadImportData();
      }
    },

    selectedDatabase() {
      if (this.selectedDatabase) {
        this.getProperties();
      }
    },

    category() {
      if (this.category) {
        this.getCategories();
      }
    },
  },

  methods: {
    async loadWorkspaces() {
      this.workspaces = await axios.get('/workspaces').then((res) => res.data);
    },

    async getDatabases() {
      this.databases = await axios.get('/notion/databases').then((res) => res.data);
    },

    async getProperties() {
      this.properties = await axios.get(`/notion/database/${this.selectedDatabase}/properties`).then((res) => res.data);
    },

    async getCategories() {
      this.categories = await axios.get(`/notion/database/${this.selectedDatabase}/property/${this.category}/options`).then((res) => res.data);

      if (this.selectedCategories.length === 0) {
        this.selectedCategories = this.categories.map((category) => category.value);
      }
    },

    async loadImportData() {
      try {
        const response = await axios.get(`/workspace/${this.selectedWorkspace}/notion/import-data`).then((res) => res.data);
        this.selectedDatabase = response.notion_db;
        this.title = response.title_field_name;
        this.amount = response.amount_field_name;
        this.category = response.category_field_name;
        this.selectedCategories = response.categories;
        this.date = response.spent_field_name;
        this.type = response.type_field_name;
        this.shouldReview = response.review_field_name;
        this.hourlyRun = response.hourly_run;
        this.lastSync = response.last_sync_time;
      } catch (e) {
        //
      }
    },

    async startImport() {
      try {
        this.importLoading = true;
        await axios.post(`/workspace/${this.selectedWorkspace}/notion/database/${this.selectedDatabase}/import`, {
          fields:
            {
              titleField: this.title,
              amountField: this.amount,
              categoryField: this.category,
              dateField: this.date,
              typeField: this.type,
              shouldReviewField: this.shouldReview,
              selectedCategories: this.selectedCategories,
              hourlyRun: this.hourlyRun,
              shouldStartOver: this.shouldStartOver,
            },
        });
        this.importLoading = false;
        this.$router.push({ name: 'Finance' });
      } catch (e) {
        console.error(e);
        this.importLoading = false;
      }
    },
  },

  mounted() {
    const query = new URLSearchParams(window.location.search);

    if (query.has('error')) {
      this.error = query.get('error');

      return;
    }

    this.getDatabases();
    this.loadWorkspaces();

    if (this.$store.state.workspace.currentWorkspace) {
      this.selectedWorkspace = this.$store.state.workspace.currentWorkspace.id;
    }
  },
};
</script>
